import { constantsTypes } from '../reduxConstants'

const initialValue = { value: false }

export const personSidebarView = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.PERSON_SIDEBAR:
      return action.value
    default:
      return state
  }
}
