import { constantsTypes } from '../reduxConstants'

const initialValue = 0

export const viewMode = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.VIEW_MODE:
      return action.value
    default:
      return state
  }
}
