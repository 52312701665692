import { constantsTypes } from '../reduxConstants'

const initialValue = true

export const sidebarView = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.SIDEBAR_VIEW:
      return action.value
    default:
      return state
  }
}
