import { constantsTypes } from '../reduxConstants'
const initialValue = null

export const getService = (state = initialValue, action) => {
  switch (action.type) {
    case constantsTypes.GET_SERVICE:
      return action.value
    default:
      return state
  }
}
