import { combineReducers } from 'redux'
import { getService } from './getServiceReducer'
import { viewMode } from './viewModeReducer'
import { sidebarView } from './sidebarReducer'
import { personSidebarView } from './personSidebarReducer'

export default combineReducers({
  getService,
  viewMode,
  sidebarView,
  personSidebarView,
})
